import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-send-vessel-notification-modal',
    templateUrl: './confirm-send-vessel-notification-modal.component.html',
    styleUrls: ['./confirm-send-vessel-notification-modal.component.scss']
})
export class ConfirmSendVesselNotificationModalComponent {
    model: any;
    remarks: string;

    constructor(public activeModal: NgbActiveModal) { }

    close() {
        this.activeModal.close(false);
    }

    confirm() {
        this.activeModal.close({ result: true, remarks: this.remarks });
    }
}
