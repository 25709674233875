<div class="modal-header">
    <h4 class="modal-title" translate>Upload</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <app-control label='Primary shipping agency' type="codelist" codelist="Agent"
                [initialValue]="user?.organizationId" [isDisabled]="!!user?.isAgent()"
                [(ngModel)]="model.agentId" [entity]="model" property="agentId"></app-control>
        </div>
        <div class="col-12">
            <app-control label='Primary shipping agency on duty number' type="string"
                pattern="[\d]" prepend="+" [(ngModel)]="model.dutyNumber" [entity]="model" property="dutyNumber"></app-control>
        </div>
        <div class="col-12">
            <app-control label='File' type="file" [allowedExtensions]="['.xlsx', '.xlsm']"
                [(ngModel)]="model.file" [entity]="model" property="file"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()">{{'Confirm' | translate}}</button>
</div>
