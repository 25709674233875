import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-vessel-notification-log',
    templateUrl: './vessel-notification-log.component.html',
    styleUrls: ['./vessel-notification-log.component.scss']
})
export class VesselNotificationLogComponent {
    @Input() model: any;
    @Input() canEdit: any;
    @Input() editMode: any;
}
