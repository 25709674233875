import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-itinerary',
    templateUrl: './vessel-notification-itinerary.component.html',
    styleUrls: ['./vessel-notification-itinerary.component.scss']
})
export class VesselNotificationItineraryComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationCruiseItinerary',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                { name: 'eta', label: 'ETA', type: AppControlType.DateTime, time: true, colSize: 3 },
                { name: 'portId', label: 'Port of Call', type: AppControlType.CodeList, codelist: 'Location', colSize: 3 }
            ]
        ]
    };
}
