import _ from 'lodash';

export class VesselNotification {
    public portOfCallEta = null;
    public portOfCallEtd = null;
    public lastPortEtd = null;
    public mdhIssuedDate = null;
    public primaryCallPurposeId: number;
    public callPurposes: any[];
    public yearNumber: string = null;

    containsCallPurpose(id: number) {
        return this.primaryCallPurposeId === id || _.some(this.callPurposes, o => o.callPurposeId === id);
    }

    containsAnyCallPurpose(...ids: number[]) {
        return _.indexOf(ids, this.primaryCallPurposeId) >= 0 || _.some(this.callPurposes, o => _.indexOf(ids, o.callPurposeId) >= 0);
    }
}
