import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-list-results-content',
    templateUrl: './app-list-results-content.component.html',
    styleUrls: ['./app-list-results-content.component.scss']
})
export class AppListResultsContentComponent {
    @Input() title = 'Results';
    @Input() nrOfActiveFilters: number;
}
