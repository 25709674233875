import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-store-product',
    templateUrl: './vessel-notification-store-product.component.html',
    styleUrls: ['./vessel-notification-store-product.component.scss']
})
export class VesselNotificationStoreProductComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationStoreProduct',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                { name: 'productName', label: 'Name of Article', maxlength: 255 },
                { name: 'quantity', label: 'Quantity', type: AppControlType.Number, decimals: 2 },
                { name: 'unitOfMeasureId', label: 'UoM', type: AppControlType.CodeList, codelist: 'UnitOfMeasure' },
                { name: 'locationOnBoard', label: 'Location on Board', maxlength: 100 }
            ],
            [
                { name: 'remarks', label: 'Other Information', maxlength: 250 }
            ]
        ]
    };
}
