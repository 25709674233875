import { Component } from '@angular/core';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-create-general-remark-modal',
    templateUrl: './create-general-remark-modal.component.html',
    styleUrls: ['./create-general-remark-modal.component.scss'],
    providers: [BreezeViewService]
})
export class CreateGeneralRemarkModalComponent extends AbstractBreezeViewComponent {
    entityName = 'VesselNotificationGeneralRemark';
    vesselNotificationId: number;
    userId: number;
    editedRemark: any;
    remarkText: string;

    constructor(breezeViewService: BreezeViewService,
        public activeModal: NgbActiveModal) {
        super(breezeViewService);
    }

    createEntity() {
        return super.createEntity({ vesselNotificationId: this.vesselNotificationId });
    }

    override fetch() {
        this.remarkText = this.editedRemark.remark;
        return this.model = this.editedRemark;
    }

    close() {
        this.activeModal.close(false);
    }

    canConfirm() {
        return !!this.remarkText;
    }

    async confirm() {
        if (this.model.private === true) this.model.privatedById = this.userId;
        this.model.remark = this.remarkText;

        if (this.mode === ViewMode.create) {
            this.activeModal.close({
                data: { remark: this.model },
                result: true
            });
        } else {
            await this.saveChanges({ redirectToList: false, redirectToViewMode: false, silent: true });
            this.activeModal.close(true);
        }
    }
}
