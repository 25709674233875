
<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title" translate>Import IHS Vessels</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-filter (search)="search()" (clearFilter)="clearFilter()">
                <div class="row" all-filter>
                    <div class="col">
                        <app-control label='IMO Number' type="number" format="#"
                            [maxlength]="7" [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Call Sign' [maxlength]="7"
                            [(ngModel)]="filter.callSign" [entity]="filter" property="callSign"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='MMSI' type="number" format="'#'"
                            [maxlength]="9" [(ngModel)]="filter.mmsi" [entity]="filter" property="mmsi"></app-control>
                    </div>
                </div>
            </app-filter>
            <app-list-results-content [nrOfActiveFilters]="nrOfActiveFilters">
                <app-grid [data]="query" [selectable]="{ mode: 'single' }" (selectionChange)="onSelectionChange($event)">
                    <kendo-grid-checkbox-column [width]="45"></kendo-grid-checkbox-column>
                    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="shipName"></kendo-grid-column>
                    <kendo-grid-column title="{{'IMO Number' | translate}}" field="ihslRorImoShipNo"></kendo-grid-column>
                    <kendo-grid-column title="{{'MMSI Number' | translate}}" field="maritimeMobileServiceIdentityMMSINumber"></kendo-grid-column>
                    <kendo-grid-column title="{{'Call sign' | translate}}" field="callSign"></kendo-grid-column>
                    <kendo-grid-column title="{{'Type' | translate}}" field="shipTypeLevel4"></kendo-grid-column>
                    <kendo-grid-column title="{{'Flag' | translate}}" field="flagName"></kendo-grid-column>
                    <kendo-grid-column title="{{'Gross T. (Tons)' | translate}}" field="grossTonnage"></kendo-grid-column>
                    <kendo-grid-column title="{{'LOA (m)' | translate}}" field="lengthOverallLoa"></kendo-grid-column>
                </app-grid>
            </app-list-results-content>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
            <button type="button" class="btn btn-primary" [disabled]="!canImport()" (click)="import()">{{'Import' | translate}}</button>
        </div>
    </ng-template>
</app-loader>
