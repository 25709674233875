<kendo-drawer-container>
    <kendo-drawer #drawer mode="overlay" position="end" (keyup.enter)="search.emit(); drawer.toggle()">
        <ng-template kendoDrawerTemplate>
            <button class="close-button" (click)="drawer.toggle()">
                <fa-icon icon="times"></fa-icon>
            </button>
            <div class="filter-header pb-2">
                <h2>
                    <fa-icon [icon]="['fas','filter']" class="font-size: 12px"></fa-icon> {{title | translate}}
                </h2>
            </div>
            <ng-content></ng-content>
            <div class="mt-2 p-1 d-flex justify-content-center" *ngIf="showFilterButtons">
                <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter.emit()">
                    <fa-icon class="ml-1" icon="trash" ></fa-icon> <span class="btn-text">{{'Clear' | translate}}</span>
                </button>
                <button *ngIf="showResetButton" class="btn btn-sm btn-info mr-2" (click)="resetOptions.emit(); clearFilter.emit(); search.emit()">
                    <fa-icon class="ml-1" icon="undo"></fa-icon> <span class="btn-text">{{'Reset' | translate}}</span>
                </button>
                <button class="btn btn-sm btn-primary" (click)="search.emit(); drawer.toggle()">
                    <fa-icon class="ml-1" icon="search"></fa-icon> <span class="btn-text">{{'Search' | translate}}</span>
                </button>
            </div>
        </ng-template>
    </kendo-drawer>
    <kendo-drawer-content>
        <button class="k-button btn-primary toggle-button box-shadow strong" (click)="drawer.toggle()">
            <fa-icon icon="filter"></fa-icon>
        </button>
    </kendo-drawer-content>
</kendo-drawer-container>
