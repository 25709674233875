import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderComponent {
    @Input() title: string;
    @Input() breadcrumb: any[];
    @Input() titleColor: string;
}
