import { Component, Input } from '@angular/core';
import { User } from '@common/models/User';
import { BreezeService } from '@common/services/breeze.service';
import { GridEditService } from '@common/services/grid-edit.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-waste-departure',
    templateUrl: './vessel-notification-waste-departure.component.html',
    styleUrls: ['./vessel-notification-waste-departure.component.scss'],
    providers: [BreezeService, GridEditService]
})
export class VesselNotificationWasteDepartureComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    constructor(public gridEdit: GridEditService) { }

    defaultDisplayValue(property, type): string {
        return this.gridEdit.defaultDisplayValue(property, type, this.model.wasteDeliveryId);
    }

    updateSequenceNumber(): void {
        _.each(this.model.wastesDeparture, (p: any, i) => p.receiptNumber = i + 1);
    }
}
