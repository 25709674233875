<ngb-accordion activeIds="attachment-info">
    <ngb-panel id="attachment-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>{{title}}</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div *ngIf="tabs">
                <kendo-tabstrip>
                    <kendo-tabstrip-tab [selected]="true" title="{{'All' | translate}}">
                        <ng-template kendoTabContent>
                            <app-vessel-notification-attachment-grid [attachments]="attachments" [canEdit]="canEdit"
                                [editMode]="false" [tabs]="tabs" [user]="user"></app-vessel-notification-attachment-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Miscellaneous' | translate}}">
                        <ng-template kendoTabContent>
                            <app-vessel-notification-attachment-grid [attachments]="miscAttachments" [canEdit]="canEdit"
                                [editMode]="false" [tabs]="tabs" [user]="user"></app-vessel-notification-attachment-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
            <div *ngIf="!tabs">
                <app-vessel-notification-attachment-grid [attachments]="attachments" [canEdit]="canEdit"
                    [editMode]="editMode" [tabs]="tabs" [user]="user"></app-vessel-notification-attachment-grid>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
