import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateVesselNotification } from '@common/guards/vessel-notification.deactivate';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { EditorModule } from '@progress/kendo-angular-editor';
import { VesselNotificationComponentModule } from 'app/vessel-notification/vessel-notification.module';
import { VesselShortNotificationGridComponent } from './components/vessel-short-notification-grid/vessel-short-notification-grid.component';
import { VesselShortNotificationListComponent } from './components/vessel-short-notification-list/vessel-short-notification-list.component';
import { VesselShortNotificationViewContentComponent } from './components/vessel-short-notification-view-content/vessel-short-notification-view-content.component';
import { VesselShortNotificationViewComponent } from './components/vessel-short-notification-view/vessel-short-notification-view.component';

export const VESSEL_SHORT_NOTIFICATION_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselShortNotificationListComponent
    },
    {
        path: 'create',
        component: VesselShortNotificationViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateVesselNotification]
    },
    {
        path: 'edit/:id',
        component: VesselShortNotificationViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'view/:id',
        component: VesselShortNotificationViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    declarations: [
        VesselShortNotificationViewComponent,
        VesselShortNotificationListComponent,
        VesselShortNotificationViewContentComponent,
        VesselShortNotificationGridComponent
    ],
    exports: [
        VesselShortNotificationViewComponent,
        VesselShortNotificationListComponent,
        VesselShortNotificationViewContentComponent,
        VesselShortNotificationGridComponent
    ],
    imports: [CommonModule, EditorModule, VesselNotificationComponentModule],
    providers: [CanDeactivateView, CanDeactivateVesselNotification]
})
export class VesselShortNotificationComponentModule { }


@NgModule({
    imports: [CommonModule, VesselShortNotificationComponentModule, RouterModule.forChild(VESSEL_SHORT_NOTIFICATION_ROUTES)],
})
export class VesselShortNotificationModule { }
