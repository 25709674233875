<app-entity-form [options]="formOptions" [grid]="attachmentGrid"></app-entity-form>
<app-grid #attachmentGrid [data]="displayedAttachments">
    <kendo-grid-column title="{{'Name' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{ row.attachment?.name || row.name }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Attachment Type' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.attachmentTypeId | codelist:'AttachmentType' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Private' | translate}}">
        <ng-template let-row kendoGridCellTemplate>
            <app-checkbox [disabled]="true" [(ngModel)]="row.private"></app-checkbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Remarks' | translate}}" field="remarks"></kendo-grid-column>
    <kendo-grid-column title="{{'Created Date' | translate}}" field="createdDate" format="g"></kendo-grid-column>
    <kendo-grid-column title="{{'Created By' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.createdById | codelist:'User' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="overflow-unset" [hidden]="editMode" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
        </ng-template>
    </kendo-grid-column>
</app-grid>