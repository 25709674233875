<ngb-accordion activeIds="itinerary-info">
    <ngb-panel id="itinerary-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Cruise Itinerary</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-entity-form [options]="formOptions" [grid]="itineraryGrid"></app-entity-form>
            <hr class="my-3"/>
            <app-grid #itineraryGrid [data]="model.cruiseItineraries">
                <kendo-grid-column title="{{'ETA' | translate}}" field="eta" format="g"></kendo-grid-column>
                <kendo-grid-column title="{{'Port of Call' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.portId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title="Supporting Documents" [user]="user"></app-vessel-notification-attachment>