export enum DeclarationType {
    Cargo = 'CargoDeclaration',
    CrewEffects = 'CrewEffectsDeclaration',
    CrewList = 'CrewListDeclaration',
    DangerousGoods = 'DangerousGoodsDeclaration',
    General = 'GeneralDeclaration',
    Health = 'HealthDeclaration',
    PassengerList = 'PassengerDeclaration',
    Security = 'SecurityDeclaration',
    ShipsStore = 'ShipsDeclaration',
    Waste = 'WasteDeclaration'
}
