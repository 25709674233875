import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-certificates',
    templateUrl: './vessel-certificates.component.html',
    styleUrls: ['./vessel-certificates.component.scss']
})
export class VesselCertificatesComponent {
    @Input() editMode: boolean;
    @Input() model: any;
    @Input() user: User;
    @Input() canEdit: boolean;
    @Input() notification: any;

    certificateFormOptions: EntityFormOptions = {
        entityName: 'VesselCertificate',
        canEdit: () => this.user?.hasPermission(VesselNotificationPermissions.Edit.certificates) && this.editMode && this.canEdit,
        propertyGroups: [
            [
                { name: 'typeId', label: 'Type', type: AppControlType.CodeList, codelist: 'CertificateType' },
                { name: 'countryId', label: 'Issuing Country', type: AppControlType.CodeList, codelist: 'Country' },
                { name: 'issueDate', label: 'Issue Date', type: AppControlType.DateTime },
                { name: 'permanent', label: 'Permanent', type: AppControlType.Boolean, colSize: 1 },
                { name: 'expirationDate', label: 'Expiration Date', type: AppControlType.DateTime, isDisabled: (propertyGroups) => propertyGroups.permanent },
                { name: 'description', label: 'Description', colSize: 3 }
            ],
            [
                { name: 'attachment', label: 'Attachment', type: AppControlType.File }
            ]
        ]
    };
}
