import { Component, Input } from '@angular/core';
import { User } from '@common/models/User';
import { BreezeService } from '@common/services/breeze.service';
import { GridEditService } from '@common/services/grid-edit.service';

@Component({
    selector: 'app-vessel-notification-waste',
    templateUrl: './vessel-notification-waste.component.html',
    styleUrls: ['./vessel-notification-waste.component.scss'],
    providers: [BreezeService, GridEditService]
})
export class VesselNotificationWasteComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    constructor(public gridEdit: GridEditService) { }

    defaultDisplayValue(property, type): string {
        return this.gridEdit.defaultDisplayValue(property, type, this.model.wasteDeliveryId);
    }
}
