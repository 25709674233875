import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-attachment-grid',
    templateUrl: './vessel-notification-attachment-grid.component.html',
    styleUrls: ['./vessel-notification-attachment-grid.component.scss']
})
export class VesselNotificationAttachmentGridComponent implements OnInit {
    @Input() attachments: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() user: User;
    @Input() tabs: Boolean;

    selectedTab: number;
    displayedAttachments: any[];

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationAttachment',
        canEdit: () => this.canEdit,
        onRemove: () => this.filterAttachments(),
        getCollection: () => this.attachments,
        onAdd: () => this.filterAttachments(),
        beforeAdd: model => {
            if (!this.user?.isSystemUser) model.ownerId = this.user?.organizationId || 0;

            model.createdDate = new Date();
            model.createdById = this.user?.id;
            model.createdOnTab = this.selectedTab;
        },
        propertyGroups: [
            [
                { name: 'attachment', label: 'Attachment', type: AppControlType.File, colSize: 4 },
                { name: 'attachmentTypeId', label: 'Attachment Type', type: AppControlType.CodeList, codelist: 'AttachmentType', colSize: 3 },
                { name: 'remarks', label: 'Remarks', type: AppControlType.String, maxlength: 255, colSize: 3 },
                { name: 'private', label: 'Private', type: AppControlType.Boolean, colSize: 2 }
            ]
        ]
    };

    constructor(private router: Router) { }

    ngOnInit(): void {
        if (this.user?.isSystemUser) {
            this.formOptions.propertyGroups.push([
                { name: 'ownerId', label: 'Owner', colSize: 3 }
            ]);
        }

        this.selectedTab = parseInt(this.router.routerState.snapshot.root.queryParams.vnTab, null);
        this.filterAttachments();
    }

    filterAttachments() {
        this.displayedAttachments = this.tabs ? this.attachments : this.attachments.filter(a => a.createdOnTab === this.selectedTab);
    }
}
