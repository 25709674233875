<app-loader [isBusy]="!model">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title" translate>Vessel Notification {{model.yearNumber}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col text-center font-weight-bold h6">
                    List of ship's defects:
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12" *ngFor="let item of model.defects">
                    {{item.defect.name}}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <app-control label='Defect Remarks' type="textarea"
                        [isDisabled]="true" [(ngModel)]="model.defectRemarks" [entity]="model" property="defectRemarks"></app-control>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="close()" translate>Ok</button>
        </div>
    </ng-template>
</app-loader>