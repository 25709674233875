<div *ngIf="canEdit() && canAdd()" class="row mb-4 mt-3">
    <div class="col">
        <div class="float-right">
            <button class="btn btn-sm btn-primary" (click)="create()" translate>Add</button>
        </div>
    </div>
</div>
<div class="mt-3" *ngIf="options.propertyGroups?.length > 0 && !!viewMode">
    <div class="card mb-3" style="padding: 10px;">
        <div class="row" *ngFor="let properties of options.propertyGroups; let i = index" [ngClass]="{ 'mt-2': i > 0 }">
            <div [ngClass]="property.colSize ? 'col-' + property.colSize : 'col'" *ngFor="let property of getDisplayedProperties(properties)">
                <app-control
                    [(ngModel)]="model[getName(property)]" [entity]="" property="model[getName(property)]"
                    (ngModelChange)="onPropertyChange(property, $event)"
                    [property]="getName(property)"
                    [entity]="model"
                    [label]="property.label"
                    [type]="property.type"
                    [options]="property.options"
                    [textField]="property.textField || 'label'"
                    [valueField]="property.valueField || 'value'"
                    [time]="property.time || false"
                    [pattern]="property.pattern"
                    [maxlength]="property.maxlength"
                    [fetch]="property.fetch"
                    [fetchOnOpen]="!!property.fetch"
                    [codelist]="property.codelist"
                    [decimals]="property.decimals"
                    [prepend]="property.prepend"
                    [min]="property.min"
                    [initialValue]="property.initialValue"
                    [isDisabled]="isDisabled(property)">
                </app-control>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="float-right">
                    <button class="btn btn-sm btn-primary mr-2" (click)="confirm()">Confirm</button>
                    <button class="btn btn-sm btn-error" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<kendo-grid-column #removeColumn title="" [width]="60">
    <ng-template kendoGridCellTemplate let-row>
        <button class="btn btn-danger btn-xs" (click)="remove(row)">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-template>
</kendo-grid-column>
<kendo-grid-column #editColumn title="" [width]="60">
    <ng-template kendoGridCellTemplate let-row>
        <button class="btn btn-primary btn-xs" (click)="edit(row)">
            <fa-icon icon="edit"></fa-icon>
        </button>
    </ng-template>
</kendo-grid-column>
