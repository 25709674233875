<ngb-accordion activeIds="general">
    <ngb-panel id="general">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>General Remarks</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" *ngIf="editMode">
                <div class="col">
                    <div class="float-right">
                        <button class="btn btn-sm btn-primary" (click)="editRemark()" translate>Add Remark</button>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <h3 *ngIf="remarksList.length > 0; else noRemarks" translate>General Remarks List</h3>
                <ng-template #noRemarks>
                    <p translate>No Remarks</p>
                </ng-template>
            </div>
            <ng-template ngFor let-generalRemark [ngForOf]="remarksList">
                <div class="row pl-3 pr-3 mb-2">
                    <div class="col simple-container">
                        <div class="row">
                            <div class="col-11">
                                <span [innerHtml]="generalRemark.remark"></span>
                            </div>
                            <div class="col-1" *ngIf="editMode">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-primary mr-2" (click)="editRemark(generalRemark)"><fa-icon icon="edit"></fa-icon></button>
                                    <button class="btn btn-sm btn-danger" (click)="removeRemark(generalRemark)"><fa-icon icon="trash"></fa-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
