<div class="action-bar" [ngClass]="{'fixed minimized': collapsed}" *ngIf="hasAnyVisibleGroup()">
    <div class="action-bar-group toggle" *ngIf="collapsible" (click)="toggleActionBarVisibility()">
        <div class="group-label" style="height: 19px;">
            <fa-icon [icon]="['fas', !collapsed ? 'chevron-circle-up' : 'chevron-circle-down']"></fa-icon>
        </div>
        <div class="action-bar-item">
            <div class="btn arrow" [ngClass]="{'hidden': collapsed}">
            </div>
        </div>
    </div>
    <ng-template ngFor let-actionGroup [ngForOf]="items">
        <div ngbDropdown #dropdown="ngbDropdown" class="action-bar-group"
            (mouseover)="openActionGroupDropdown(dropdown)" *ngIf="isVisible(actionGroup)">
            <div ngbDropdownAnchor class="group-label" translate>{{actionGroup.label}}</div>
            <div ngbDropdownMenu>
                <ng-template ngFor let-item [ngForOf]="actionGroup.items">
                    <div *ngIf="label(item)" class="action-bar-item medium" translate>
                        <button class="btn" [disabled]="isDisabled(item, actionGroup)" *ngIf="isVisible(item)"
                            (click)="onClick(item, actionGroup)" [ngStyle]="{'background-color': item.backgroundColor}">
                            <fa-icon *ngIf="icon(item)" [icon]="['fas', icon(item)]"></fa-icon>
                            {{label(item)}}
                        </button>
                    </div>
                    <div class="small-buttons-container" *ngIf="item.smallItems">
                        <ng-template ngFor let-smallItem [ngForOf]="item.smallItems">
                            <div class="action-bar-item small">
                                <button class="btn" [disabled]="isDisabled(smallItem, actionGroup)"
                                    (click)="onClick(smallItem, actionGroup)" *ngIf="isVisible(smallItem)">
                                    <fa-icon [icon]="['fas', icon(smallItem)]"></fa-icon>
                                    <span class="btn-label" translate>{{label(smallItem)}}</span>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </ng-template>
            </div>

            <ng-template ngFor let-item [ngForOf]="actionGroup.items">
                <div class="action-bar-item" *ngIf="label(item) && !item.options">
                    <button class="btn" [disabled]="isDisabled(item, actionGroup)" *ngIf="isVisible(item)"
                        (click)="onClick(item, actionGroup)" [ngClass]="{'hidden': collapsed}"
                        [ngStyle]="{'background-color': item.backgroundColor}">
                        <fa-icon *ngIf="!collapsed" [icon]="['fas', icon(item)]"></fa-icon>
                        <div *ngIf="!collapsed" class="btn-label" translate>{{label(item)}}</div>
                    </button>
                </div>
                <div class="action-bar-item split-btn" *ngIf="item.options && item.isSplit">
                    <div>
                        <button class="btn icon-btn" [disabled]="isDisabled(item, actionGroup)" *ngIf="isVisible(item)"
                            (click)="onClick(item, actionGroup)" [ngClass]="{'hidden': collapsed}">
                            <fa-icon *ngIf="!collapsed" [icon]="['fas', icon(item)]"></fa-icon>
                        </button>
                    </div>
                    <div>
                        <div ngbDropdown>
                            <button style="line-height: 20px;" class="btn" type="button" ngbDropdownToggle
                                *ngIf="!collapsed" [ngClass]="{'hidden': collapsed}">
                                <div *ngIf="!collapsed" class="btn-label caret" translate>{{label(item)}}</div>
                            </button>
                            <div ngbDropdownMenu>
                                <ng-template ngFor let-option [ngForOf]="item.options">
                                    <button [disabled]="isDisabled(option, actionGroup)" *ngIf="isVisible(item)"
                                        ngbDropdownItem (click)="onClick(option, actionGroup)" translate>
                                        <fa-icon *ngIf="icon(option)" [icon]="['fas', icon(option)]"></fa-icon>
                                        {{label(option)}}
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-bar-item small" *ngIf="item.options && !item.isSplit">
                    <div ngbDropdown>
                        <button style="line-height: 20px;" class="btn" type="button" ngbDropdownToggle
                            *ngIf="!collapsed" [ngClass]="{'hidden': collapsed}">
                            <div *ngIf="!collapsed" style="padding-top: 15px; margin-bottom: 8px;">
                                <fa-icon [icon]="['fas', icon(item)]"></fa-icon>
                            </div>
                            <div *ngIf="!collapsed" class="btn-label caret" translate>{{label(item)}}</div>
                        </button>
                        <div ngbDropdownMenu>
                            <ng-template ngFor let-option [ngForOf]="item.options">
                                <button [disabled]="isDisabled(option, actionGroup)" *ngIf="isVisible(item)"
                                    ngbDropdownItem (click)="onClick(option, actionGroup)" translate>
                                    <fa-icon *ngIf="icon(option)" [icon]="['fas', icon(option)]"></fa-icon>
                                    {{label(option)}}
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="small-buttons-container" *ngIf="item.smallItems">
                    <ng-template ngFor let-smallItem [ngForOf]="item.smallItems">
                        <div class="action-bar-item small">
                            <button class="btn" [disabled]="isDisabled(smallItem, actionGroup)"
                                (click)="onClick(smallItem, actionGroup)"
                                *ngIf="isVisible(smallItem) && !collapsed" [ngClass]="{'hidden': collapsed}">
                                <span *ngIf="!collapsed">
                                    <fa-icon [icon]="['fas', icon(smallItem)]"></fa-icon>
                                </span>
                                <span *ngIf="!collapsed" class="btn-label" translate>{{label(smallItem)}}</span>
                            </button>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </ng-template>
    <div style="clear: both"></div>
</div>
<div *ngIf="hasAnyVisibleGroup()" [ngStyle]="{'margin-bottom': (collapsed ? 20 : 120) + 'px'}"></div>