<ngb-accordion activeIds="bunker-info">
    <ngb-panel id="bunker-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Bunkers information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-entity-form [options]="formOptions" [grid]="bunkersGrid"></app-entity-form>
            <app-grid #bunkersGrid [data]="model.bunkers">
                <kendo-grid-column title="{{'Bunker Type' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.bunkerTypeId | codelist:'BunkerType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity"></kendo-grid-column>
                <kendo-grid-column title="{{'Weight Unit' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.unitOfMeasureId | codelist:'UnitOfMeasure' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Remarks' | translate}}" field="description"></kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="card mt-3 mb-3" *ngIf="model.notificationTypeId === 'D'">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <app-control label='Was the vessel supplied with bunkers during her call in Malta?' type="yesno" [isDisabled]="!canEdit || !editMode"
                    (ngModelChange)="suppliedWithBunkersChange($event)" [(ngModel)]="model.vesselSuppliedWithBunkers" [entity]="model" property="vesselSuppliedWithBunkers"></app-control>
            </div>
        </div>
        <div *ngIf="!!model.vesselSuppliedWithBunkers">
            <div class="row">
                <div class="col-4">
                    <app-control label='Quantity of fuel supplied' type="number" format="n2" [decimals]="2"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.quantityOfFuelSupplied" [entity]="model" property="quantityOfFuelSupplied"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <app-control label='Bunker barge supplier' type="textarea"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.bunkerBargeSupplier" [entity]="model" property="bunkerBargeSupplier"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <app-control label='Road tanker supplier' type="textarea"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.roadTankerSupplier" [entity]="model" property="roadTankerSupplier"></app-control>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>