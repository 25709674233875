import { Component, OnInit } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-vessel-notification-defects-modal',
    templateUrl: './vessel-notification-defects-modal.component.html',
    styleUrls: ['./vessel-notification-defects-modal.component.scss'],
    providers: [BreezeViewService]
})
export class VesselNotificationDefectsModalComponent implements OnInit {
    vesselNotificationId: any;
    model: any;

    constructor(public activeModal: NgbActiveModal,
        private breezeViewService: BreezeViewService) { }

    ngOnInit() {
        this.breezeViewService.handleQuery('VesselNotification', { id: this.vesselNotificationId })
            .then(results => this.model = results[0]);
    }

    close() {
        this.activeModal.close();
    }
}
