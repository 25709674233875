import { Component } from '@angular/core';
import { User } from '@common/models/User';
import { UserService } from '@common/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-upload-vessel-notification-modal',
    templateUrl: './upload-vessel-notification-modal.component.html',
    styleUrls: ['./upload-vessel-notification-modal.component.scss']
})
export class UploadVesselNotificationModalComponent {
    user: User;

    model = {
        agentId: null,
        dutyNumber: null,
        file: false
    };

    constructor(public activeModal: NgbActiveModal,
        userService: UserService) {
        userService.currentUserSubject.subscribe((user) => this.user = user);
    }

    close() {
        this.activeModal.close(false);
    }

    canConfirm() {
        return this.model.file;
    }

    confirm() {
        this.activeModal.close(this.model);
    }
}
