<div class="modal-header">
    <h4 class="modal-title" translate>Notifications</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-user-notification-list [showHeader]="false" [onlyImportant]="true"></app-user-notification-list>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
</div>
