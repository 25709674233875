import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { EditorModule } from '@progress/kendo-angular-editor';
import { VesselShiftGridComponent } from './components/vessel-shift-grid/vessel-shift-grid.component';
import { VesselShiftListComponent } from './components/vessel-shift-list/vessel-shift-list.component';
import { VesselShiftViewComponent } from './components/vessel-shift-view/vessel-shift-view.component';

export const VESSEL_SHIFT_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselShiftListComponent
    },
    {
        path: 'create',
        component: VesselShiftViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'edit/:id',
        component: VesselShiftViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'view/:id',
        component: VesselShiftViewComponent,
        data: {
            mode: ViewMode.view
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    declarations: [VesselShiftViewComponent, VesselShiftListComponent, VesselShiftGridComponent],
    exports: [VesselShiftGridComponent],
    imports: [CommonModule, EditorModule],
    providers: [CanDeactivateView]
})
export class VesselShiftComponentModule { }

@NgModule({
    imports: [CommonModule, VesselShiftComponentModule, RouterModule.forChild(VESSEL_SHIFT_ROUTES)]
})
export class VesselShiftModule { }
