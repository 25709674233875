<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <app-vessel-short-notification-view-content
            (confirm)="confirm()"
            (tabSelected)="updateTabQueryParameter($event)"
            [initialSelectedTabIndex]="initialSelectedTabIndex"
            [showHeader]="true"
            [user]="user"
            [mode]="mode"
            [canEdit]="canEdit()"
            [model]="model"
            [enableSteps]="enableSteps">
        </app-vessel-short-notification-view-content>
    </ng-template>
</app-loader>
