import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { ConveyancePermissions } from '@common/classes/permissions';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { ConveyanceGridComponent } from '../conveyance-grid/conveyance-grid.component';

@Component({
    selector: 'app-conveyance-list',
    templateUrl: './conveyance-list.component.html',
    styleUrls: ['./conveyance-list.component.scss'],
    providers: [BreezeViewService],
    encapsulation: ViewEncapsulation.None
})
export class ConveyanceListComponent extends AutoFilterBreezeListComponent implements OnInit {
    queryName = 'Conveyances';
    exportCommand = 'ExportConveyances';
    createPermission = ConveyancePermissions.Action.create;
    selectedTabIndex = 0;

    @ViewChild(ConveyanceGridComponent) conveyanceGrid;

    breadcrumb = [
        {
            icon: 'file-alt',
            title: 'Conveyances'
        }
    ];

    constructor(protected breezeViewService: BreezeViewService) {
        super(breezeViewService);

        this.actionBar.push({
            label: 'Archive',
            items : [
                {
                    label: 'Archive Conveyance',
                    icon: 'archive',
                    isVisible: () => this.user?.hasPermission(ConveyancePermissions.Action.archive),
                    isDisabled: () => this.selection.length <= 0  || this.selectedTabIndex === 1,
                    onClick: () => this.archiveConveyance()
                }
            ]
        });
    }

    getDefaultFilter() {
        return { archived: this.selectedTabIndex === 1 };
    }

    print() {
        if (!this.selection.length) return;
        this.isBusy = true;

        return this.breezeViewService.handleCommand('PrintConveyances', { ids: this.selection })
            .then(content => UploadActionButtonsComponent.preview(`Conveyances.pdf`, content.$value))
            .finally(() => this.isBusy = false);
    }

    archiveConveyance() {
        this.breezeViewService.handleCommand('ArchiveConveyance', {selectedIds: this.selection});
        this.search();
    }

    canPrint() {
        return this.user?.hasPermission(ConveyancePermissions.Action.print);
    }

    canExportExcel() {
        return this.user?.hasPermission(ConveyancePermissions.Action.export);
    }

    export(exportType) {
        return super.export(exportType, this.conveyanceGrid.appGrid);
    }

    canExportPdf() {
        return false;
    }

    ngOnInit() {
        this.filter = super.getFilter();
        this.filter.archived = this.selectedTabIndex === 1;
        this.initialize();
    }

    onTabSelect(tab) {
        this.selectedTabIndex = tab.index;
        this.filter.archived = tab.index === 1;
        this.search();
    }
}
