import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CqrsService {
    constructor(private http: HttpClient) { }

    command<T>(command: string, data: any) {
        return firstValueFrom(this.http.post<T>(`api:///command/${command}`, data));
    }

    query<T>(query: string, data: any) {
        return firstValueFrom(this.http.post<T>(`api:///query/${query}`, data));
    }
}
