import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    public sentData: Observable<any>;
    private sendDataSubject = new Subject<any>();

    constructor() {
        this.sentData = this.sendDataSubject.asObservable();
    }

    emit(event: EventData) {
        this.sendDataSubject.next(event);
    }

    on(eventName: string, callback: (data: any) => void): Subscription {
        return this.sentData.pipe(
            filter((e: EventData) => e.eventName === eventName),
            map((e: EventData) => e.data)
        ).subscribe(callback);
    }
}

export class EventData {
    eventName: string;
    data: any;
    constructor(eventName: string, value: any = null) {
        this.eventName = eventName;
        this.data = value;
    }
}
