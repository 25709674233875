import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-create-departure-modal',
    templateUrl: './create-departure-modal.component.html',
    styleUrls: ['./create-departure-modal.component.scss']
})
export class CreateDepartureModalComponent {
    @Input() shortNotification = false;

    model = {
        voyage: false,
        bunker: false,
        crew: false,
        passenger: false,
        dangerousGoods: false,
        cargo: false
    };

    constructor(public activeModal: NgbActiveModal) { }

    close() {
        this.activeModal.close();
    }

    confirm() {
        this.activeModal.close(this.model);
    }
}
