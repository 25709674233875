<div class="modal-header">
    <h4 class="modal-title" translate>Create departure</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-2">
            <app-control label='Voyage' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.voyage" [entity]="model" property="voyage"></app-control>
        </div>
        <div class="col-2" *ngIf="!shortNotification">
            <app-control label='Bunkers' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.bunker" [entity]="model" property="bunker"></app-control>
        </div>
        <div class="col-2" *ngIf="!shortNotification">
            <app-control label='Cargo' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.cargo" [entity]="model" property="cargo"></app-control>
        </div>
        <div class="col-2" *ngIf="!shortNotification">
            <app-control label='DPG' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.dangerousGoods" [entity]="model" property="dangerousGoods"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Crew' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.crew" [entity]="model" property="crew"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Passengers' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.passenger" [entity]="model" property="passenger"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="confirm()">{{'Confirm' | translate}}</button>
</div>
