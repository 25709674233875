import { Injectable } from '@angular/core';
import { DialogService } from '@common/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { VesselNotificationViewComponent } from 'app/vessel-notification/components/vessel-notification-view/vessel-notification-view.component';
import { ToastrService } from 'ngx-toastr';
import { CanDeactivateView } from './view.deactivate';

@Injectable()
export class CanDeactivateVesselNotification extends CanDeactivateView<VesselNotificationViewComponent> {
    constructor(
        dialogService: DialogService,
        translateService: TranslateService,
        toastrService: ToastrService) {
        super(dialogService, translateService, toastrService);
    }

    canDeactivate(component: VesselNotificationViewComponent) {
        if (!component?.model?.vessel) {
            return new Promise<boolean>((resolve, reject) => resolve(true));
        }
        return super.canDeactivate(component);
    }
}
