<ngb-accordion activeIds="store-info">
    <ngb-panel id="store-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>IMO FAL 3 - Ship's Stores</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-entity-form [options]="formOptions" [grid]="storeGrid"></app-entity-form>
            <hr class="my-3"/>
            <app-grid #storeGrid [data]="model.storeProducts">
                <kendo-grid-column title="{{'Name of Article' | translate}}" field="productName"></kendo-grid-column>
                <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity"></kendo-grid-column>
                <kendo-grid-column title="{{'UoM' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.unitOfMeasureId | codelist:'UnitOfMeasure' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Location on Board' | translate}}" field="locationOnBoard"></kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
