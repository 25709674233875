<div class="jumbotron mt-3">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Document</th>
                <th scope="col">Link</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Vessel notification upload template</td>
                <td><a [href]="environment.excelTemplateFile" download>Download</a></td>
            </tr>
        </tbody>
    </table>
</div>
