<ngx-loading-bar [height]="'4px'" color="#fff" ref="router"></ngx-loading-bar>
<ngx-loading-bar [height]="'4px'" color="#fff" ref="html"></ngx-loading-bar>
<div kendoDialogContainer></div>

<app-header (toggleSidebar)="sidebar.toggle()"></app-header>

<app-sidebar #sidebar [ngStyle]="{'display': !isAuthenticated ? 'none' : 'block'}"></app-sidebar>
<main class="h-100" [ngStyle]="{'margin-left': isAuthenticated ? (sidebar.isCollapsed ? '60px' : '300px') : '0px'}">

    <kendo-splitter #consoleSplitter orientation="vertical" style="height: 100%; overflow-y: hidden;">
        <kendo-splitter-pane>
            <div class="container-fluid px-3 pb-3" style="height: 100%; overflow-y: scroll;">
                <router-outlet></router-outlet>
            </div>
        </kendo-splitter-pane>
        <kendo-splitter-pane [collapsible]="true" size="20%" *ngIf="consoleWindowService.opened">
            <ng-template appConsoleContent></ng-template>
        </kendo-splitter-pane>
    </kendo-splitter>
    <router-outlet name="print"></router-outlet> <!-- Auxilliary outlet for printing HTML documents -->
</main>



