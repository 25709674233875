<div class="modal-header">
    <h4 class="modal-title" translate>{{options.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelAction()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="message">
        <h6>{{message}}</h6>
    </div>
    <ng-container *ngIf="options.properties?.length > 0">
        <div class="row" *ngFor="let property of options.properties; let i = index" [ngClass]="{ 'mt-2': i > 0 }">
            <div class="col">
                <app-control
                    [type]="property.type"
                    [(ngModel)]="model[getName(property)]" [entity]="" property="model[getName(property)]"
                    [property]="getName(property)"
                    [label]="property.label"
                    [codelist]="property.codelist"
                    [time]="property.time"
                    [pattern]="property.pattern"
                    [allowedExtensions]="property.allowedExtensions"
                    [isDisabled]="!property.isDisabled ? false : property.isDisabled(model)"
                    [filter]="property.filter?.bind(this)"
                    [prepend]="property.prepend"
                    [min]="property.min"
                    [max]="property.max"
                    [format]="property.format"
                    [initialValue]="property.initialValue">
                </app-control>
            </div>
        </div>
    </ng-container>
    <ng-template #template></ng-template>
</div>
<div class="modal-footer">
    <button class="btn btn-danger" (click)="cancelAction()" translate>{{cancelText}}</button>
    <button class="btn btn-primary" (click)="confirmAction()" [disabled]="!canConfirmPredicate(model)" translate>{{confirmText}}</button>
</div>
