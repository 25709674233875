<div class="modal-header">
    <h4 class="modal-title" translate>Change agency</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <app-control label='Module Type' type="codelist" codelist="ModuleType"
                [fetchOnOpen]="true" [fetch]="fetchModuleTypes" [isDisabled]="false"
                (ngModelChange)="changeFromOrg($event)" [(ngModel)]="model.moduleTypeId" [entity]="model" property="moduleTypeId"></app-control>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <app-control label='Organization From' [initialValue]="agentId"
                type="codelist" codelist="Agent" [(ngModel)]="model.organizationFromId" [entity]="model" property="organizationFromId"></app-control>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <app-control label='Organization To' type="codelist" codelist="Agent"
                [isDisabled]="!model.moduleTypeId" [(ngModel)]="model.organizationToId" [entity]="model" property="organizationToId"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()">{{'Confirm' | translate}}</button>
</div>
