import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { VesselShiftGridComponent } from '../vessel-shift-grid/vessel-shift-grid.component';

@Component({
    selector: 'app-vessel-shift-list',
    templateUrl: './vessel-shift-list.component.html',
    styleUrls: ['./vessel-shift-list.component.scss'],
    providers: [BreezeViewService],
    encapsulation: ViewEncapsulation.None
})
export class VesselShiftListComponent extends AutoFilterBreezeListComponent implements OnInit {
    queryName = 'VesselShifts';
    exportCommand = 'ExportVesselShifts';
    selectedTabIndex = 0;

    @ViewChild(VesselShiftGridComponent) vesselShiftGrid: VesselShiftGridComponent;

    breadcrumb = [
        {
            icon: 'file',
            title: 'Vessel Shiftings'
        }
    ];

    constructor(protected breezeViewService: BreezeViewService) { super(breezeViewService); }

    override ngOnInit() {
        this.filter = super.getFilter();
        this.filter.archived = this.selectedTabIndex === 1;
        this.initialize();
    }

    override getDefaultFilter() {
        return {
            archived: this.selectedTabIndex === 1
        };
    }

    override canCreateNew() {
        return false;
    }

    override canExportPdf() {
        return false;
    }

    override export(exportType) {
        return super.export(exportType, this.vesselShiftGrid.appGrid);
    }

    onTabSelect(tab) {
        this.selectedTabIndex = tab.index;
        this.filter.archived = tab.index === 1;
        this.search();
    }
}
