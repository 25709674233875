import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DrawerComponent } from '@progress/kendo-angular-layout';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppDrawerComponent {
    @Input() showFilterButtons = true;
    @Input() showResetButton = false;
    @Input() title = 'FILTERS';

    @Output() public search = new EventEmitter<any>();
    @Output() public clearFilter = new EventEmitter<any>();
    @Output() public resetOptions = new EventEmitter<any>();

    @ViewChild(DrawerComponent) public drawer: DrawerComponent;
}
