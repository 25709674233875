import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ConveyanceGridComponent } from './components/conveyance-grid/conveyance-grid.component';
import { ConveyanceListComponent } from './components/conveyance-list/conveyance-list.component';
import { ConveyanceViewComponent } from './components/conveyance-view/conveyance-view.component';

export const CONVEYANCE_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: ConveyanceListComponent
    },
    {
        path: 'create',
        component: ConveyanceViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'edit/:id',
        component: ConveyanceViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'view/:id',
        component: ConveyanceViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    declarations: [ConveyanceListComponent, ConveyanceViewComponent, ConveyanceGridComponent],
    exports: [ConveyanceGridComponent],
    imports: [CommonModule, EditorModule],
    providers: [CanDeactivateView]
})
export class ConveyanceComponentModule { }

@NgModule({
    imports: [CommonModule, ConveyanceComponentModule, RouterModule.forChild(CONVEYANCE_ROUTES)]
})
export class ConveyanceModule { }
