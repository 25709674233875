<ngb-accordion activeIds="print" *ngIf="isTabVisible">
    <ngb-panel id="print">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>IMO FAL Forms Printing</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" *ngIf="!shortNotification">
                <div class="col-3">
                    <app-control label='General Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printGeneral" [entity]="" property="printGeneral"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(3)">
                    <app-control label='Cargo Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printCargo" [entity]="" property="printCargo"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(4)">
                    <app-control label="{{'Ship\'s Store Declaration'}}" type="boolean"
                        [isDisabled]="false" [(ngModel)]="printShipsStore" [entity]="" property="printShipsStore"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(5)">
                    <app-control label="{{'Crew Effect\'s Declaration'}}" type="boolean"
                        [isDisabled]="false" [(ngModel)]="printCrewEffects" [entity]="" property="printCrewEffects"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(5)">
                    <app-control label='Crew List' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printCrewList" [entity]="" property="printCrewList"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(6)">
                    <app-control label='Passenger List' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printPassengerList" [entity]="" property="printPassengerList"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(2)">
                    <app-control label='Dangerous Goods Manifest' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printDangerousGoods" [entity]="" property="printDangerousGoods"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(9)">
                    <app-control label='Health Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printHealth" [entity]="" property="printHealth"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(8) && model.notificationTypeId === 'A'">
                    <app-control label='Waste Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printWaste" [entity]="" property="printWaste"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(7) && model.notificationTypeId === 'A'">
                    <app-control label='Ship pre-arrival security information form' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printSecurity" [entity]="" property="printSecurity"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="shortNotification">
                <div class="col-3">
                    <app-control label='General Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printGeneral" [entity]="" property="printGeneral"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(1)">
                    <app-control label='Crew List' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printCrewList" [entity]="" property="printCrewList"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(2)">
                    <app-control label='Passenger List' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printPassengerList" [entity]="" property="printPassengerList"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(3) && model.notificationTypeId === 'A'">
                    <app-control label='Waste Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printWaste" [entity]="" property="printWaste"></app-control>
                </div>
                <div class="col-3" *ngIf="isTabVisible(4)">
                    <app-control label='Health Declaration' type="boolean"
                        [isDisabled]="false" [(ngModel)]="printHealth" [entity]="" property="printHealth"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3 mt-3">
                    <button class="btn btn-primary btn-block" (click)="print()" translate>Print</button>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>