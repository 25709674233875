import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-bunker',
    templateUrl: './vessel-notification-bunker.component.html',
    styleUrls: ['./vessel-notification-bunker.component.scss']
})
export class VesselNotificationBunkerComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationBunker',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                { name: 'bunkerTypeId', label: 'Bunker Type', type: AppControlType.CodeList, codelist: 'BunkerType' },
                { name: 'quantity', label: 'Quantity', type: AppControlType.Number, decimals: 2 },
                { name: 'unitOfMeasureId', label: 'Weight Unit', type: AppControlType.CodeList, codelist: 'BunkerUnitOfMeasures' },
                { name: 'description', label: 'Remarks', maxlength: 255 }
            ]
        ]
    };

    suppliedWithBunkersChange(event: any) {
        if (!this.model.vesselSuppliedWithBunkers)
            this.model.quantityOfFuelSupplied = this.model.bunkerBargeSupplier = this.model.roadTankerSupplier = null;
    }
}
